
import * as React from 'react';

// mui
import Box from '@mui/material/Box';

// libs
/**
 * Docs:
 * https://www.npmjs.com/package/react-type-animation
 * https://react-type-animation.netlify.app/examples
 */
import { TypeAnimation } from 'react-type-animation';

type Wrapper = 'p' | 'div' | 'span' | 'strong' | 'a' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'b';
type Speed = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31 | 32 | 33 | 34 | 35 | 36 | 37 | 38 | 39 | 40 | 41 | 42 | 43 | 44 | 45 | 46 | 47 | 48 | 49 | 50 | 51 | 52 | 53 | 54 | 55 | 56 | 57 | 58 | 59 | 60 | 61 | 62 | 63 | 64 | 65 | 66 | 67 | 68 | 69 | 70 | 71 | 72 | 73 | 74 | 75 | 76 | 77 | 78 | 79 | 80 | 81 | 82 | 83 | 84 | 85 | 86 | 87 | 88 | 89 | 90 | 91 | 92 | 93 | 94 | 95 | 96 | 97 | 98 | 99;


export interface TypeEffectProps {
  sequenceSSR: string
  sequence: (string | number)[]
  wrapper?: Wrapper
  speed?: Speed
  repeat?: number
  startDelay?: number
  style?: React.CSSProperties
}

// const sequenceExample = [
//   'We develop',
//   1000,
//   'We develop Metaverses',
//   1000,
//   'We develop iGaming',
//   1000,
//   'We develop AI',
//   1000
// ]

export default function TypeEffect(props: TypeEffectProps) {

  const refTimerDelay = React.useRef<NodeJS.Timeout | null>(null);

  // states
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {

    if (typeof props.startDelay === undefined) {
      setMounted(true);
      return;
    } else {
      refTimerDelay.current = setTimeout(() => {
        setMounted(true);
      }, props.startDelay);
    }

    return () => {
      if (refTimerDelay.current) {
        clearTimeout(refTimerDelay.current);
      }
    }
  }, []);


  if (!mounted) {
    return (
      <Box component={props.wrapper || 'div'}>
        {props.sequenceSSR}
      </Box>
    )
  }

  return (
    <Box component={props.wrapper || 'div'}>
      <TypeAnimation
        style={{
          cursor: 'default',
          ...props.style,
        }}
        sequence={props.sequence}
        wrapper={props.wrapper}
        speed={props.speed || 50}
        repeat={props.repeat || Infinity}
      />
    </Box>
  );
};
