import project from "@configs/project";


interface TeamItem {
  name: string;
  position: string;
  slug: string;
  avatar: string;
  email?: string;
  calendly?: string;
}

export const defaultCalendly = "markolofsen/meeting";

export const teamList: TeamItem[] = [
  {
    "name": "Dan Merriam",
    "position": "Strategic Partner",
    "slug": "dan-merriam",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Ivan Tyumenyev",
    "position": "CEO",
    "slug": "ivan-tyumenyev",
    "avatar": project.assets.team.v1,
    "email": "svirinstel@gmail.com",
    "calendly": "ivantyumenyev/30min",
  },
  {
    "name": "Mark Korotin",
    "position": "CPO",
    "slug": "mark-korotin",
    "avatar": project.assets.team.v1,
    "email": "markolofsen@gmail.com",
    "calendly": defaultCalendly,
  },
  {
    "name": "Alexei Petrov",
    "position": "CTO",
    "slug": "alexei-petrov",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Ksenia Balashova",
    "position": "COO",
    "slug": "ksenia-balashova",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Oliver Smith",
    "position": "Finance Manager",
    "slug": "oliver-smith",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Elena Sokolova",
    "position": "Sales Manager",
    "slug": "elena-sokolova",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Maria Ivanova",
    "position": "Project Manager",
    "slug": "maria-ivanova",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "John Miller",
    "position": "Project Manager",
    "slug": "john-miller",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Euhen Solovev",
    "position": "Research Engineer",
    "slug": "euhen-solovev",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Dmitry Kuznetsov",
    "position": "Backend Developer",
    "slug": "dmitry-kuznetsov",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Sergei Petrov",
    "position": "Full-Stack Developer",
    "slug": "sergei-petrov",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "William Johnson",
    "position": "Full-Stack Developer",
    "slug": "william-johnson",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Sofia Fernandez",
    "position": "UI/UX Designer",
    "slug": "sofia-fernandez",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Anna Smith",
    "position": "Content Manager",
    "slug": "anna-smith",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "John Davis",
    "position": "Legal Counsel",
    "slug": "john-davis",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Maria Smolina",
    "position": "Customer Relations Manager",
    "slug": "maria-smolina",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Pavel Novikov",
    "position": "DevOps Specialist",
    "slug": "pavel-novikov",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Alex Turner",
    "position": "DevOps Specialist",
    "slug": "alex-turner",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Yulia Antonova",
    "position": "QA Engineer",
    "slug": "yulia-antonova",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Richard Parker",
    "position": "QA Engineer",
    "slug": "richard-parker",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Andrei Ivanov",
    "position": "AI & Software Developer",
    "slug": "andrei-ivanov",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Rajesh Verma",
    "position": "AI & Software Developer",
    "slug": "rajesh-verma",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Arun Patel",
    "position": "AI & Software Developer",
    "slug": "arun-patel",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Nikita Romanov",
    "position": "Unreal Engine Developer",
    "slug": "nikita-romanov",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Nina Hernandez",
    "position": "3D Artist UE",
    "slug": "nina-hernandez",
    "avatar": project.assets.team.v1,
  },
  {
    "name": "Alex Johnson",
    "position": "Unreal Engine Developer",
    "slug": "alex-johnson",
    "avatar": project.assets.team.v1,
  }
]
