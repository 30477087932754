
import { useState } from "react";

// @core
import project from "@configs/project";

// @configs
import env from '@core/env'

// mui
import { Button, Stack, Typography, Link } from "@mui/material";
import Box from '@mui/material/Box';
import { TextField } from "@mui/material";

// icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// @components
import FormikForm, { FormikValues, FormikMethods, Yup } from '@components/Formik/'

// @modules
import AirtableAPI from "@modules/Airtable";


export default function RequestForm() {

  // states
  const [success, setSuccess] = useState<boolean>(false)


  // methods
  const handleSubmit = async (values: FormikValues) => {

    const body = {
      name: values.name,
      email: values.email,
      company: values.company,
      budget: values.budget,
      description: values.description,
    }
    // alert(JSON.stringify(body, null, 2))

    return AirtableAPI.addWebsiteRequest(body).then((res) => {
      if (res.status === 200) {
        // alert('Super!')
        setSuccess(true)
      } else {
        alert('Error!')
        console.error(res.data)
      }

      return res
    })
  }

  const renderSuccess = () => {
    if (!success) return null

    return (
      <Box sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 5,
        px: 10,
        py: 30,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
        bgcolor: 'customColors.overlay'
      }}>
        <CheckCircleOutlineIcon sx={{
          color: 'success.main',
          fontSize: '5rem',
        }} />
        <Box>
          <Typography variant="h5" align="center" gutterBottom>
            Thank you for your request!
          </Typography>
          <Typography variant="body1" align="center">
            We will contact you soon.
          </Typography>
        </Box>
      </Box>
    )
  }


  const renderForm = () => {

    if (success) return null

    const initialValues = () => {
      if (env.isDev) {
        return {
          name: 'Mark',
          email: 'demo@demo.com',
          company: 'Test company',
          budget: '1000$',
          description: 'Hello!',
        }
      }

      return {
        name: '',
        email: '',
        company: '',
        budget: '',
        description: '',
      }

    }

    return (
      <FormikForm
        initialValues={initialValues()}
        validationSchema={Yup.object({
          name: Yup
            .string()
            // .min(8, 'Password should be of minimum 8 characters length')
            .required('Name is required'),
          email: Yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),
          company: Yup
            .string()
            .required('Company is required'),
          budget: Yup
            .string()
            .required('Budget is required'),
          description: Yup
            .string()
            .required('Description is required'),
        })}
        readOnLoad
        onSubmit={handleSubmit}
        onRead={undefined}
      >
        {(formik: FormikValues, methods: FormikMethods) => (
          <Stack spacing={5}>

            <Box sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                md: '1fr 1fr',
              },
              gap: 5,
            }}>

              <TextField
                name="name"
                label="Your name"
                disabled={methods.disabled}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={methods.isError('name')}
                helperText={methods.helperText('name')}
              />

              <TextField
                fullWidth
                name="company"
                label="Company name"
                disabled={methods.disabled}
                value={formik.values.company}
                onChange={formik.handleChange}
                error={methods.isError('company')}
                helperText={methods.helperText('company')}
              />

              <TextField
                name="email"
                label="Email"
                type="email"
                disabled={methods.disabled}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={methods.isError('email')}
                helperText={methods.helperText('email')}
              />

              <TextField
                name="budget"
                label="Budget"
                disabled={methods.disabled}
                value={formik.values.budget}
                onChange={formik.handleChange}
                error={methods.isError('budget')}
                helperText={methods.helperText('budget')}
              />

            </Box>


            <Box>
              <TextField
                fullWidth
                multiline
                minRows={5}
                maxRows={20}
                name="description"
                label="Project Description"
                disabled={methods.disabled}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={methods.isError('description')}
                helperText={methods.helperText('description')}
                placeholder="Tell us about your project. What problem can we help you solve?"
              />
            </Box>

            <Box>
              <Button
                disabled={methods.disabled}
                type="submit"
                size="large"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
              >
                Submit
              </Button>
            </Box>
          </Stack>

        )}
      </FormikForm>
    )
  }

  return (
    <Box>

      <Box sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        columnGap: 30,
        rowGap: 10,
      }}>

        <Box sx={{
          width: {
            xs: '100%',
            md: '30%',
          }
        }}>

          <Stack spacing={5}>
            <Box>
              <Typography variant="h1" gutterBottom>
                Book a Consultation
              </Typography>
              <Typography variant="body1" color="text.secondary">
                We’ve worked with clients of all sizes, from enterprise brands to funded startups. Let’s talk about your project and how we can help provide value.
              </Typography>
            </Box>

            <Box>

              <Typography variant="h6" gutterBottom>
                Our contact details:
              </Typography>
              <Typography variant="body1">
                {project.contacts.phone}
              </Typography>

              <Link href={`mailto:${project.contacts.email}`} variant="body1">
                {project.contacts.email}
              </Link>

            </Box>
            <Box>

              <Typography variant="h6" gutterBottom>
                Join our team:
              </Typography>

              <Typography variant="body1">
                We’re always looking for talented people to join our team. Send us your resume and portfolio.
              </Typography>

            </Box>
          </Stack>

        </Box>


        <Box sx={{
          flex: 1,
        }}>

          {renderSuccess()}
          {renderForm()}

        </Box>

      </Box>
    </Box>
  );
}