// @configs
import routes from "@configs/routes";

// mui
import { Typography, Avatar } from "@mui/material";
import Box from '@mui/material/Box';

// icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// @components
import CardsSlider from '@components/CardsSlider'
import { Link, ButtonLink } from "@components/Link";

// @data
import { teamList } from "@data/team";


export default function TeamGallery() {
  return (
    <Box>
      <Box sx={{
        mb: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Typography variant="h4" color="text.secondary">
          Team Schedule
        </Typography>
        <Box>
          <ButtonLink
            href={routes.PAGES.TEAM}
            endIcon={<ArrowForwardIcon />}
            variant="outlined"
            color="secondary">
            All Team
          </ButtonLink>
        </Box>
      </Box>
      <Box sx={{
        // bgcolor: 'rgba(0,0,0,.02)',
        // borderRadius: 2,
        // py: 5,
        // border: 'solid 1px',
        // borderColor: 'divider',
        borderLeft: 0,
        borderRight: 0,
      }}>
        <CardsSlider
          // arrowDataKey="avatar-image"
          breakpoints={{
            xs: {
              perPage: 2,
              gap: 0,
            },
            sm: {
              perPage: 3,
              gap: 0,
            },
            md: {
              perPage: 5,
              gap: 0,
            },
            lg: {
              perPage: 6,
              gap: 0,
            },
            xl: {
              perPage: 7,
              gap: 0,
            },
          }}
        >
          {teamList.map((item, index) => (
            <Link
              key={index}
              href={routes.PAGES.TEAM_MEMBER.replace('[slug]', item.slug)}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
                // border: '1px solid',
                // borderColor: 'divider',
                // bgcolor: 'background.paper',
                borderRadius: 2,
                py: 5,
                px: 10,
                transition: 'all 0.3s ease',
                '&:hover': {
                  bgcolor: 'customColors.overlay'
                }
              }}
            >
              <Avatar
                data-key="avatar-image"
                // src={item.avatar}
                alt={item.name}
                sx={{
                  width: 50,
                  height: 50,
                }} />

              <Box sx={{
                mt: 5,
                textAlign: 'center',
                '& > *': {
                  display: 'block',
                  textAlign: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: 130,
                }
              }}>
                <Typography variant="subtitle2">
                  {item.name}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {item.position}
                </Typography>
              </Box>
            </Link>
          ))}
        </CardsSlider>
      </Box>
    </Box>
  )
}